import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class PermissionAccessService {
  private helper = new JwtHelperService();

  constructor() {}

  public hasPermission(requiredPermissions: string[]): boolean {
    const token = localStorage.getItem('auth_token');
    if (token) {
      try {
        const decodedToken = this.helper.decodeToken(token);
        const userPermissions: string[] = decodedToken.Policy || [];

        const hasPermission = requiredPermissions.some((permission) =>
          userPermissions.includes(permission),
        );

        return hasPermission;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }
}
