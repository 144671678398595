export class PermissionConstants {
  // Admin
  public static readonly CanManageUsersPolicy = 'CanManageUsersPolicy';
  public static readonly CanManageRolesPolicy = 'CanManageRolesPolicy';
  public static readonly CanManageSettingsPolicy = 'CanManageSettingsPolicy';
  public static readonly CanDeactivateOrDeleteUserPolicy =
    'CanDeactivateOrDeleteUserPolicy';
  public static readonly CanManageSecurityPolicy = 'CanManageSecurityPolicy';
  public static readonly CanMonitorLogsAndUserActivitiesPolicy =
    'CanMonitorLogsAndUserActivitiesPolicy';

  public static readonly AdministratorPermissions = [
    this.CanManageUsersPolicy,
    this.CanManageRolesPolicy,
  ];

  // Planner
  public static readonly CanScheduleAndManageFlightsAndMissionsPolicy =
    'CanScheduleAndManageFlightsAndMissionsPolicy';
  public static readonly CanAssignPilotCrewEquipmentToMissionPolicy =
    'CanAssignPilotCrewEquipmentToMissionPolicy';
  public static readonly CanManageFlightScheduleAndBookingPolicy =
    'CanManageFlightScheduleAndBookingPolicy';
  public static readonly CanModifyAndCancelBookingPolicy =
    'CanModifyAndCancelBookingPolicy';
  public static readonly CanMonitorPilotAndEquipmentCertificationsPolicy =
    'CanMonitorPilotAndEquipmentCertificationsPolicy';
  public static readonly CanHandleCustomerBookingsPolicy =
    'CanHandleCustomerBookingsPolicy';
  public static readonly CanAccessCustomerAndBookingInfoPolicy =
    'CanAccessCustomerAndBookingInfoPolicy';
  public static readonly CanAccessAvailabilityOfHelicoptersAndOtherAssetsPolicy =
    'CanAccessAvailabilityOfHelicoptersAndOtherAssetsPolicy';
  public static readonly CanReceiveNotificationOfMaintenanceAndCertificationPolicy =
    'CanReceiveNotificationOfMaintenanceAndCertificationPolicy';
  public static readonly CanModifyBookingStatusPolicy =
    'CanModifyBookingStatusPolicy';
  public static readonly CanViewAllMissionsPolicy = 'CanViewAllMissionsPolicy';
  public static readonly CanViewPersonsPolicy = 'CanViewPersonsPolicy';
  public static readonly CanAddPersonsPolicy = 'CanAddPersonsPolicy';
  public static readonly CanEditPersonsPolicy = 'CanEditPersonsPolicy';

  public static readonly PlannerCertificationPermissions = [
    this.CanMonitorPilotAndEquipmentCertificationsPolicy,
  ];

  public static readonly PlannerPersonPermissions = [this.CanViewPersonsPolicy];

  // Pilot
  public static readonly CanViewPersonalFlightScheduleAndMissionDetailsPolicy =
    'CanViewPersonalFlightScheduleAndMissionDetailsPolicy';
  public static readonly CanLogFlightHoursAndOtherInfoPolicy =
    'CanLogFlightHoursAndOtherInfoPolicy';
  public static readonly CanReceiveNotificationOfCertificationRenewalAndMedicalCheckPolicy =
    'CanReceiveNotificationOfCertificationRenewalAndMedicalCheckPolicy';
  public static readonly CanAccessRecencyInfoAndManagePersonalCertificationStatusPolicy =
    'CanAccessRecencyInfoAndManagePersonalCertificationStatusPolicy';
  public static readonly CanOfflineAccessFlightDetailsAndSyncCapabilitiesPolicy =
    'CanOfflineAccessFlightDetailsAndSyncCapabilitiesPolicy';
  public static readonly CanViewRelevantMissionPolicy =
    'CanViewRelevantMissionPolicy';

  public static readonly PilotCertificationPermissions = [
    this.CanAccessRecencyInfoAndManagePersonalCertificationStatusPolicy,
  ];

  // Back office Employee
  public static readonly CanManageAndUpdateMasterDataPolicy =
    'CanManageAndUpdateMasterDataPolicy';
  public static readonly CanMonitorAndUpdateTaskStatusPolicy =
    'CanMonitorAndUpdateTaskStatusPolicy';
  public static readonly CanHandleAdministrativeBookingAndSchedulingTasksPolicy =
    'CanHandleAdministrativeBookingAndSchedulingTasksPolicy';
  public static readonly CanReceiveNotificationAndAlertsForMaintenanceAndCertificationPolicy =
    'CanReceiveNotificationAndAlertsForMaintenanceAndCertificationPolicy';
  public static readonly CanFetchCustomerDataAndManageCrmIntegrationPolicy =
    'CanFetchCustomerDataAndManageCrmIntegrationPolicy';
  public static readonly CanViewAndUpdateTechnicalLogsPolicy =
    'CanViewAndUpdateTechnicalLogsPolicy';
  public static readonly CanTrackAndManagePermitsAndCertificationsPolicy =
    'CanTrackAndManagePermitsAndCertificationsPolicy';
  public static readonly CanTrackAndManageInventoryOfAssetsPolicy =
    'CanTrackAndManageInventoryOfAssetsPolicy';

  public static readonly BackofficeCertificationPermissions = [
    this.CanTrackAndManagePermitsAndCertificationsPolicy,
  ];

  // Head of training
  public static readonly CanManageTrainingSchedulesAndCertificationsPolicy =
    'CanManageTrainingSchedulesAndCertificationsPolicy';
  public static readonly CanMonitorPilotTrainingStatusAndCertificationValidityPolicy =
    'CanMonitorPilotTrainingStatusAndCertificationValidityPolicy';
  public static readonly CanSendTrainingListsAndUpdatesToBackofficePolicy =
    'CanSendTrainingListsAndUpdatesToBackofficePolicy';

  public static readonly HeadOfTrainingCertificationPermissions = [
    this.CanMonitorPilotTrainingStatusAndCertificationValidityPolicy,
    this.CanManageTrainingSchedulesAndCertificationsPolicy,
  ];

  // Task specialist
  public static readonly CanViewPersonalFlightSchedulesAndMissionDetailsPolicy =
    'CanViewPersonalFlightSchedulesAndMissionDetailsPolicy';
  public static readonly CanLogHoursAndOtherInfoPolicy =
    'CanLogHoursAndOtherInfoPolicy';
  public static readonly CanAccessAndManagePersonalCertificationStatusPolicy =
    'CanAccessAndManagePersonalCertificationStatusPolicy';

  public static readonly TaskSpecialistCertificationPermissions = [
    this.CanAccessAndManagePersonalCertificationStatusPolicy,
  ];

  // App Permissions
  // TODO: Move to a new file(s)

  //////////////////////// CERTIFICATIONS ////////////////////////

  public static readonly AppViewCertificationsModulePermissions = [
    ...this.PlannerCertificationPermissions,
    ...this.PilotCertificationPermissions,
    ...this.BackofficeCertificationPermissions,
    ...this.HeadOfTrainingCertificationPermissions,
    ...this.TaskSpecialistCertificationPermissions,
  ];

  public static readonly AppViewAllCertificationsPermissions = [
    ...this.PlannerCertificationPermissions,
    ...this.BackofficeCertificationPermissions,
    ...this.HeadOfTrainingCertificationPermissions,
  ];

  public static readonly AppViewPersonalCertificationsPermissions = [
    this.CanAccessRecencyInfoAndManagePersonalCertificationStatusPolicy,
    this.CanAccessAndManagePersonalCertificationStatusPolicy,
    this.CanTrackAndManagePermitsAndCertificationsPolicy,
    this.CanManageTrainingSchedulesAndCertificationsPolicy,
  ];

  public static readonly AppEditCertificationPermissions = [
    this.CanAccessRecencyInfoAndManagePersonalCertificationStatusPolicy,
    this.CanAccessAndManagePersonalCertificationStatusPolicy,
    this.CanTrackAndManagePermitsAndCertificationsPolicy,
    this.CanManageTrainingSchedulesAndCertificationsPolicy,
  ];

  public static readonly AppRenewalCertificationPermissions = [
    this.CanAccessRecencyInfoAndManagePersonalCertificationStatusPolicy,
    this.CanAccessAndManagePersonalCertificationStatusPolicy,
    this.CanTrackAndManagePermitsAndCertificationsPolicy,
    this.CanManageTrainingSchedulesAndCertificationsPolicy,
  ];

  public static readonly AppCanToggleShowAllCertificationPermissions = [
    this.CanTrackAndManagePermitsAndCertificationsPolicy,
    this.CanManageTrainingSchedulesAndCertificationsPolicy,
    this.CanMonitorPilotAndEquipmentCertificationsPolicy,
  ];

  //////////////////////// PILOT-CREW ////////////////////////

  public static readonly AppAccessPilotCrewModulePermissions = [
    ...this.AppViewCertificationsModulePermissions,
  ];

  public static readonly PilotCrewModulePermissions = [
    ...this.AppViewCertificationsModulePermissions,
  ];

  //////////////////////// PERSON ////////////////////////

  public static readonly AppViewPersonsPermissions = [
    this.CanViewPersonsPolicy,
  ];

  public static readonly AppAddPersonsPermissions = [this.CanAddPersonsPolicy];

  public static readonly AppEditPersonsPermissions = [
    this.CanEditPersonsPolicy,
  ];

  public static readonly AppViewContactsModulePermissions = [
    ...this.AppViewPersonsPermissions,
  ];
}
